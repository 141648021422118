<template>
  <div>
    <Main-top
      v-if="!categoryLoading"
      class="blog-main-top"
      :title="indexTitle"
      :filterMode="true"
      :categoryData="categoryData"
      :routeCategory="routeCategory"
      :routeTime="routeTime"
    ></Main-top>
    <div v-if="!countLoading" class="filterResults-index-bg">
      <v-container class="inner-container">
        <div
          v-if="showEmptyBlock"
          style="min-height: 400px"
          class="d-flex flex-column justify-center align-center"
        >
          <h2 class="text-3 text-center pa-5" style="color: #00304f">
            {{ dataEmptyText }}
          </h2>
          <Main-btn v-if="showBackBtn" :to="{ name: 'blog-index' }">{{
            actionBackText
          }}</Main-btn>
        </div>

        <div v-else>
          <template v-if="hasFilter">
            <v-row class="mt-0 pb-8 border-bottom">
              <v-col v-for="item in list" :key="item.id" cols="12" md="4">
                <SmallItem :item="item" />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <BigItem v-if="bigItem" :item="bigItem" />
            <v-row class="mt-0 pb-8 border-bottom">
              <v-col v-for="item in otherItems" :key="item.id" cols="12" md="4">
                <SmallItem :item="item" />
              </v-col>
            </v-row>
          </template>
        </div>
      </v-container>
    </div>

    <v-container v-if="has_next" class="inner-container">
      <div class="text-center">
        <v-divider class="d-none d-md-block my-10"></v-divider>
        <Main-btn @click="more" class="mt-0">{{ $t("action.look-more") }}</Main-btn>
      </div>
    </v-container>
  </div>
</template>

<script lang="babel" type="text/babel">
import indexMixins from '@/components/page/index.js'
import indexList from '@/components/page/indexList.js'
export default {
  mixins: [indexMixins, indexList],
  components: {
    BigItem: ()=>import('@/components/blog/bigItem.vue'),
    SmallItem: ()=>import('@/components/blog/smallItem.vue')
  },
  data: ()=>({

  }),
  computed: {
    seoTitle() {
      if(this.isBlog) return this.$store.getters['base/blog_seo_title']
      if(this.isMedia) return this.$store.getters['base/media_seo_title']
      return ''
    },
    seoDescription() {
      if(this.isBlog) return this.$store.getters['base/blog_seo_description']
      if(this.isMedia) return this.$store.getters['base/media_seo_description']
      return ''
    },
    seoKeywords() {
      if(this.isBlog) return this.$store.getters['base/blog_seo_keywords']
      if(this.isMedia) return this.$store.getters['base/media_seo_keywords']
      return ''
    },
    isBlog() {
      return this.$route.name === 'blog-index'
    },
    isMedia() {
      return this.$route.name === 'media-index'
    },
    indexTitle() {
      if(this.isBlog) return this.$t('page.blog')
      if(this.isMedia) return this.$t('page.media')
      return ''
    },
    breadcrumb() {
      return [
        {
          title: this.indexTitle,
          name: 'blog-index'
        }
      ]
    },
    list() {
      if(!this.indexData) return []
      return this.indexData.data
    },
    bigItem() {
      if(!this.indexData) return null
      const array = this.indexData.data.slice(0,1)
      return array[0]
    },
    otherItems() {
      if(!this.indexData) return []
      return this.indexData.data.slice(1)
    },
    indexApi() {
      return this.$api.blog.public.index
    },
    categoryIndexApi() {
      return this.$api.blog.public.categoryIndex
    },
    categoryParams() {
      return {
        site_id: this.site_id,
        pager: 0,
        is_media: +this.isMedia
      }
    },
    perParams() {
      if(this.hasFilter) return {per: 9}
      return {per: 6}
    },
    otherParams() {
      const params = {per: this.perParams.per}
      if(this.isMedia) params.is_media = 1
      if(!this.hasFilter) params.exclude = this.firstItemId
      return params
    },
    countApi() {
      return this.$api.blog.public.count
    },
    countParams() {
      return {
        site_id: this.site_id,
        is_media: +this.isMedia
      }
    },
    needFirstFetch() {
      return true
    },
    firstItemId() {
      if(!this.indexData) return ''
      if(_isEmpty(this.indexData.data)) return ''
      return this.indexData.data[0].id
    },
  },
  watch: {
    '$route': {
      deep: true,
      handler(n, o) {
        if(n.name != o.name) this.count()
      },
    },
  },
  methods: {
    async firstGetIndex() {
      try {
        const params = {...this.params, per: 1}
        const res = await this.indexApi(params);
        this.indexData = res
      } catch (err) {
        console.error(err);
      }
    },
  },
};

</script>

<style lang="sass" type="text/sass">
.filterResults-index-bg
  background-image: url('~@/assets/img/bg/filterResultBg.png')
  background-position: center top
  -webkit-backdrop-filter: blur(30px)
</style>