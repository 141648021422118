// blog + cloud + white-book 的列表頁會用到
import pageMixins from './page.js'
import qs from "qs";
export default {
  mixins: [pageMixins],
  data: () => ({
    categoryData: null,
    categoryLoading: false,
  }),
  computed: {
    site_id() {
      return this.$store.getters['base/siteId']
    },
    routeQuery() {
      return qs.parse(this.$route.query.q);
    },
    hasFilter() {
      return !_isEmpty(this.routeQuery)
    },
    routeTime() {
      if (!this.routeQuery) return "";
      return this.routeQuery.time || "";
    },
    routeCategory() {
      if (!this.routeQuery) return [];
      if (!this.routeQuery.category) return [];
      return this.routeQuery.category || [];
    },
    categoryIndexApi() {
      // TODO
      return null
    },
    breadcrumb() {
      // TODO
      return []
    },
    isIndexPage() {
      return true
    },
    categoryParams() {
      // TODO
      return {
        site_id: this.site_id,
        pager: 0,
      }
    },
  },
  watch: {
    '$route': {
      deep: true,
      immediate: true,
      async handler() {
        this.setBreadcrumb();
        await this.$nextTick()
        setTimeout(() => {
          this.$root.$emit('setNavBreadcrumbHeight')
        }, 600)

        this.$vuetify.goTo(0, {
          duration: 400,
        })

      },
    },
  },
  created() {
    this.categoryIndex();
    this.onIndexFetch();
  },
  beforeDestroy() {
    this.$root.$off("indexFetchDone");
  },
  methods: {
    onIndexFetch() {
      this.$root.$on(`indexFetchDone`, this.handleAfterIndexFetch)
    },
    handleAfterIndexFetch() {
      this.defaultAfterIndexFetch()
      this.afterIndexFetch()
    },
    defaultAfterIndexFetch() {

    },
    afterIndexFetch() {
      //TODO
      this.setupMeta() 
    },
    async categoryIndex() {
      if (!this.categoryIndexApi) return;
      this.$store.dispatch("base/loading");
      this.categoryLoading = true;
      try {
        this.categoryData = await this.categoryIndexApi(this.categoryParams);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("base/loading", false);
        this.categoryLoading = false;
      }
    },
  },
};
